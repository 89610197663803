import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'

import vco from 'v-click-outside'
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2'
import VueSlideBar from 'vue-slide-bar'
import VueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import Vuelidate from 'vuelidate'
import VueMoment from 'vue-moment'
import VueFileSelector from 'vue-file-selector'

import Lightbox from 'vue-easy-lightbox'
import VueMask from 'v-mask'
import i18n from './i18n'
import store from '@/state/store'
import { EventBus } from '@/utils/event-bus'

import App from './App.vue'

import Loading from '@/components/loading-overlay'

import { initFirebaseBackend } from './helpers/firebase/authUtils'

import { configureFakeBackend } from './helpers/fakebackend/fake-backend'
  
const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  // databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
}

if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
  initFirebaseBackend(firebaseConfig)
} else if (process.env.VUE_APP_DEFAULT_AUTH === 'fakebackend') {
  configureFakeBackend()
}

import '@/assets/scss/app.scss'
import '@/assets/css/player/player_list.css'
import '@/assets/css/player/starplyr.css'
import '@/assets/css/player_new/index.min.css'

Vue.config.productionTip = false

Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VueMoment)
Vue.use(VueFilterPrettyBytes)
Vue.use(VueFileSelector)

Vue.use(require('vue-chartist'))
Vue.use(VueMask)
Vue.use(Lightbox)
Vue.component('Loading', Loading)

//전역변수(vuex로 대체됨)
let globalData = new Vue({
  data: {
    $socket: null,
    $isSocketConnecting: false,
    $isSocketConnected: false,
    $transList: [],
    // $resumable: null,
    $isLoading: false,

    $completeTranscodingList: new Map(), // 완료된 트랜스코딩 정보 [fid : fdid-index]
    $retryTranscodingList: new Map(), // 트랜스코딩 재시도 목록 [transcoderID : fid](encodingList 중복 데이터 막기용도)
    $transcodingCompleteCount: 0, // 트랜스코딩이 완료된 수[fdid index]
    $encodingList: new Map(), // 트랜스코딩할 인코딩 정보 [ transcoderID : { transcodingInfo }]
  },
})

Vue.mixin({
  computed: {
    $socket: {
      get: function () {
        return globalData.$data.$socket
      },
      set: function (newSocket) {
        globalData.$data.$socket = newSocket
      },
    },
    $isSocketConnecting: {
      get: function () {
        return globalData.$data.$isSocketConnecting
      },
      set: function (isSocketConnecting) {
        globalData.$data.$isSocketConnecting = isSocketConnecting
      },
    },
    $isSocketConnected: {
      get: function () {
        return globalData.$data.$isSocketConnected
      },
      set: function (isSocketConnected) {
        globalData.$data.$isSocketConnected = isSocketConnected
      },
    },
    $transList: {
      get: function () {
        return globalData.$data.$transList
      },
      set: function (transList) {
        globalData.$data.$transList = transList
      },
    },
    // $resumable: {
    //   get: function() {
    //     return globalData.$data.$resumable
    //   },
    //   set: function(resumable) {
    //     globalData.$data.$resumable = resumable
    //   },
    // },
    $isLoading: {
      get: function () {
        return globalData.$data.$isLoading
      },
      set: function (isLoading) {
        globalData.$data.$isLoading = isLoading
      },
    },
    $completeTranscodingList: {
      get: function () {
        return globalData.$data.$completeTranscodingList
      },
      set: function () {
        globalData.$data.$completeTranscodingList
      },
    },
    $retryTranscodingList: {
      get: function () {
        return globalData.$data.$retryTranscodingList
      },
      set: function () {
        globalData.$data.$retryTranscodingList
      },
    },
    $transcodingCompleteCount: {
      get: function () {
        return globalData.$data.$transcodingCompleteCount
      },
      set: function (i) {
        globalData.$data.$transcodingCompleteCount = i
      },
    },
    $encodingList: {
      get: function () {
        return globalData.$data.$encodingList
      },
      set: function () {
        globalData.$data.$encodingList
      },
    },
  },
})

new Vue({
  created() {
    //eventbus 등록
    EventBus.$on('eventbus', (event) => {
      if (event === 'logout') {
        //로그아웃시 처리
        this.dataClear()
      } else if (event === 'loadingStart') {
        globalData.$data.$isLoading = true
      } else if (event === 'loadingEnd') {
        globalData.$data.$isLoading = false
      }
    })
  },
  methods: {
    /**
     * 전역 변수 초기화
     */
    dataClear() {
      try {
        if (globalData.$data.$socket) globalData.$data.$socket.close()
      } catch (e) {
        //
      }
      globalData.$data.$socket = null
      globalData.$data.$isSocketConnected = false
      globalData.$data.$isSocketConnecting = false
      globalData.$data.$transList = []
      // globalData.$data.$resumable = null
      globalData.$data.$isLoading = false

      globalData.$data.$completeTranscodingList = new Map() // 완료된 트랜스코딩 정보 [fid : fdid-index]
      globalData.$data.$retryTranscodingList = new Map() // 트랜스코딩 재시도 목록 [transcoderID : fid](encodingList 중복 데이터 막기용도)
      globalData.$data.$transcodingCompleteCount = 0 // 트랜스코딩이 완료된 수[fdid index]
      globalData.$data.$encodingList = new Map() // 트랜스코딩할 인코딩 정보 [ transcoderID : { transcodingInfo }]
    },
  },
  router,
  store,
  i18n,
  el: '#app',
  render: (h) => h(App),
}).$mount('#app')
